var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "d-flex flex-wrap w-100 px-sm-4",
        staticStyle: { "background-color": "var(--spainhn-rojo-corporativo)" },
      },
      [
        _c("div", { staticClass: "py-2 px-2 ms-auto" }, [
          _c(
            "a",
            {
              staticClass: "link-light",
              attrs: { href: "/clientes/reservas" },
            },
            [_c("small", [_vm._v(_vm._s(_vm.$t("default.CLIENTES_RESERVAS")))])]
          ),
        ]),
        _c("div", { staticClass: "py-2 border-start px-2" }, [
          _c(
            "a",
            {
              staticClass: "link-light",
              attrs: { href: "/clientes/cambiaclave" },
            },
            [
              _c("small", [
                _vm._v(
                  " " + _vm._s(_vm.$t("default.CLIENTES_PASSWORD_RESET")) + " "
                ),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "py-2 border-start px-2" }, [
          _c(
            "a",
            {
              staticClass: "link-light",
              attrs: { href: "/clientes/cambiaemail" },
            },
            [
              _c("small", [
                _vm._v(
                  " " + _vm._s(_vm.$t("default.CLIENTES_EMAIL_CHANGE")) + " "
                ),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "py-2 border-start px-2" }, [
          _c(
            "a",
            { staticClass: "link-light", attrs: { href: "/clientes/editar" } },
            [
              _c("small", [
                _vm._v(
                  " " + _vm._s(_vm.$t("default.CLIENTES_EDIT_PROFILE")) + " "
                ),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "py-2 border-start px-2 me-auto" }, [
          _c(
            "a",
            {
              staticClass: "link-light",
              attrs: { href: "#" },
              on: { click: _vm.logout },
            },
            [
              _c("small", [
                _vm._v(" " + _vm._s(_vm.$t("default.CLIENTES_LOGOUT")) + " "),
              ]),
            ]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "container bg-white my-5 border py-5" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("p", { staticClass: "spainhn-subtitle-2-black" }, [
            _vm._v(_vm._s(_vm.$t("default.CLIENTES_PASSWORD_RESET_TEXT"))),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-12 col-lg-8 mx-auto" },
          [
            _c("b-form-input", {
              staticClass: "my-1",
              attrs: { placeholder: _vm.$t("default.CLIENTES_NEW_PASSWORD") },
            }),
            _c("b-form-input", {
              staticClass: "my-1",
              attrs: {
                placeholder: _vm.$t("default.CLIENTES_NEW_PASSWORD_CONFIRM"),
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col-12 col-lg-8 mx-auto" }, [
          _c(
            "div",
            { staticClass: "d-flex flex-wrap justify-content-center pt-3" },
            [
              _c("div", { staticClass: "col-12 mx-auto" }, [
                _c("div", { staticClass: "row mx-auto" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 mx-auto" },
                    [
                      _c(
                        "mdb-btn",
                        {
                          staticClass: "spainhn-button mx-auto",
                          attrs: { color: "indigo", size: "lg" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("default.CLIENTES_CHANGE_PASSWORD_BUTTON")
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c(
                        "mdb-btn",
                        {
                          staticClass: "spainhn-button mx-auto",
                          attrs: { color: "indigo", size: "lg" },
                          on: { click: () => _vm.$router.push("/clientes") },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("default.CLIENTES_CANCEL_PASSWORD_BUTTON")
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }